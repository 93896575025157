var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ma-4 mb-8"},[_c('v-toolbar',{staticClass:"mb-2"},[_c('v-toolbar-title',[_vm._v("ScotsWork Job Status")])],1),(_vm.positions.length === 0)?_c('v-card',[_c('v-card-title',[_vm._v("No Positions Found")]),_c('v-card-text',[_c('p',[_vm._v("It looks like you do not have any student employment positions or work-study placements for the current school year.")]),_c('p',[_vm._v("Email "),_c('a',{attrs:{"href":"mailto:studentemployment@covenant.edu"}},[_vm._v("StudentEmployment@covenant.edu")]),_vm._v(" if you would like to request a student employment position or request to do work-study.")])])],1):_vm._e(),(_vm.positions.length > 0 && !_vm.checklistComplete)?_c('v-card',{staticClass:"mb-2"},[_c('v-card-title',[_vm._v("Ready-to-Work Checklist")]),_c('v-card-text',[_c('p',[_vm._v("Please complete each item in this checklist so that you will be ready to work. Once every item is complete and the semester has begun, you can report to your supervisor to being working.")])]),_c('v-expansion-panels',_vm._l((_vm.checklist),function(ref,j){
var code = ref.code;
var title = ref.title;
var text = ref.text;
var links = ref.links;
var canMarkComplete = ref.canMarkComplete;
var completed = ref.completed;
var video = ref.video;
var playAgain = ref.playAgain;
return _c('v-expansion-panel',{key:title},[_c('v-expansion-panel-header',[_c('span',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"left":"","color":completed ? 'success' : 'error'}},on),[_vm._v(_vm._s(completed ? 'fas fa-check-circle' : 'fas fa-minus-circle'))])]}}],null,true)},[_vm._v(" "+_vm._s(completed ? 'You have completed this item' : 'This item is incomplete')+" ")]),_vm._v(" "+_vm._s(title)+" ")],1)]),_c('v-expansion-panel-content',[_c('v-card-text',[_c('p',{domProps:{"innerHTML":_vm._s(text)}}),(video && completed && !playAgain)?_c('div',{staticStyle:{"background-color":"white"},style:('width:720px;height:480px'),attrs:{"contain":""}},[_c('img',{staticClass:"align-end",staticStyle:{"padding-top":"10%","margin":"0 auto","display":"block"},style:('max-height:420px'),attrs:{"src":require("@/assets/logo_stacked.jpg")}}),_c('v-btn',{staticStyle:{"margin-left":"calc(50% - 70px)","margin-top":"1em"},attrs:{"dark":""},on:{"click":function($event){return _vm.checklist.splice(j, 1, Object.assign({}, _vm.checklist[j], {playAgain: true}))}}},[_vm._v("Replay Video")])],1):(video)?_c('youtube',{attrs:{"video-id":video,"player-width":720,"player-height":480,"player-vars":{ start: 0, autoplay: 0, controls: completed ? 1 : 0, rel: 0, fs: 1 }},on:{"ended":function($event){_vm.markCompleted(code); _vm.checklist.splice(j, 1, Object.assign({}, _vm.checklist[j], {playAgain: false}))}}}):_vm._e()],1),(!completed)?_c('v-card-actions',[_vm._l((links),function(ref){
var text = ref.text;
var to = ref.to;
var href = ref.href;
return _c('v-btn',{key:code + '-' + text,attrs:{"to":to,"href":href,"target":href ? '_blank' : ''}},[_vm._v(_vm._s(text))])}),(canMarkComplete)?[_c('v-spacer'),_c('v-btn',{on:{"click":function($event){return _vm.markCompleted(code)}}},[_vm._v("Mark Completed")])]:_vm._e()],2):_vm._e()],1)],1)}),1)],1):_vm._e(),_c('v-row',_vm._l((_vm.positions),function(ref){
var name = ref.name;
var supervisor = ref.supervisor;
var email = ref.email;
var start = ref.start;
var end = ref.end;
var hours = ref.hours;
return _c('v-col',{key:name},[_c('v-card',[_c('v-card-title',[_vm._v("Position: "+_vm._s(name))]),_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Supervisor: "+_vm._s(supervisor))]),_c('v-list-item-subtitle',[_vm._v("Email: "+_vm._s(email))])],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("Start Date: "+_vm._s(_vm.stringFormatDate(start, true, true)))]),_c('v-list-item-subtitle',[_vm._v("End Date: "+_vm._s(_vm.stringFormatDate(end, true, true)))])],1)],1),(hours)?_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("Committed Weekly Hours: "+_vm._s(hours))]),_c('v-list-item-subtitle',[_c('v-btn',{attrs:{"to":"/scotswork/contract","small":"","outlined":""}},[_vm._v("Resubmit Contract to update hours")])],1)],1)],1):_vm._e()],1)],1)],1)}),1),(_vm.timeEntries.length > 0)?_c('v-card',{staticClass:"mt-2"},[_c('v-card-title',[_vm._v("Recent Timeclock Entries")]),_c('v-data-table',{attrs:{"items":_vm.timeEntries,"headers":_vm.headers,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.icon)?_c('v-icon',{attrs:{"color":item.iconColor,"left":""}},[_vm._v(_vm._s(item.icon))]):_vm._e(),_vm._v(" "+_vm._s(item.status)+" ")]}}],null,false,372922607)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }