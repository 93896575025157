<template>
  <div class="ma-4 mb-8">
    <v-toolbar class="mb-2">
      <v-toolbar-title>ScotsWork Job Status</v-toolbar-title>
    </v-toolbar>
    <v-card v-if="positions.length === 0">
      <v-card-title>No Positions Found</v-card-title>
      <v-card-text>
        <p>It looks like you do not have any student employment positions or work-study placements for the current school year.</p>
        <p>Email <a href="mailto:studentemployment@covenant.edu">StudentEmployment@covenant.edu</a> if you would like to request a student employment position or request to do work-study.</p>
      </v-card-text>
    </v-card>
    <v-card v-if="positions.length > 0 && !checklistComplete" class="mb-2">
      <v-card-title>Ready-to-Work Checklist</v-card-title>
      <v-card-text>
        <p>Please complete each item in this checklist so that you will be ready to work. Once every item is complete and the semester has begun, you can report to your supervisor to being working.</p>
      </v-card-text>
      <v-expansion-panels>
        <v-expansion-panel v-for="({ code, title, text, links, canMarkComplete, completed, video, playAgain }, j) in checklist" :key="title">
          <v-expansion-panel-header>
            <span>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" left :color="completed ? 'success' : 'error'">{{ completed ? 'fas fa-check-circle' : 'fas fa-minus-circle' }}</v-icon>
                </template>
                {{ completed ? 'You have completed this item' : 'This item is incomplete' }}
              </v-tooltip>
              {{ title }}
            </span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card-text>
              <p v-html="text"></p>
              <div v-if="video && completed && !playAgain" :style="'width:720px;height:480px'" style="background-color:white;" contain>
                <img src="@/assets/logo_stacked.jpg" :style="'max-height:420px'" style="padding-top:10%;margin:0 auto;display:block" class="align-end"/>
                <v-btn style="margin-left:calc(50% - 70px);margin-top:1em;" dark @click="checklist.splice(j, 1, { ...checklist[j], playAgain: true })">Replay Video</v-btn>
              </div>
              <youtube
                v-else-if="video"
                :video-id="video"
                :player-width="720"
                :player-height="480"
                :player-vars="{ start: 0, autoplay: 0, controls: completed ? 1 : 0, rel: 0, fs: 1 }"
                @ended="markCompleted(code); checklist.splice(j, 1, { ...checklist[j], playAgain: false })"></youtube>
            </v-card-text>
            <v-card-actions v-if="!completed">
              <v-btn v-for="{ text, to, href } in links" :key="code + '-' + text" :to="to" :href="href" :target="href ? '_blank' : ''">{{ text }}</v-btn>
              <template v-if="canMarkComplete">
                <v-spacer></v-spacer>
                <v-btn @click="markCompleted(code)">Mark Completed</v-btn>
              </template>
            </v-card-actions>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
    <v-row>
      <v-col v-for="{ name, supervisor, email, start, end, hours } in positions" :key="name">
        <v-card>
          <v-card-title>Position: {{ name }}</v-card-title>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Supervisor: {{ supervisor }}</v-list-item-title>
                <v-list-item-subtitle>Email: {{ email }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>Start Date: {{ stringFormatDate(start, true, true) }}</v-list-item-subtitle>
                <v-list-item-subtitle>End Date: {{ stringFormatDate(end, true, true) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="hours">
              <v-list-item-content>
                <v-list-item-subtitle>Committed Weekly Hours: {{ hours }}</v-list-item-subtitle>
                <v-list-item-subtitle>
                  <v-btn to="/scotswork/contract" small outlined>Resubmit Contract to update hours</v-btn>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <v-card v-if="timeEntries.length > 0" class="mt-2">
      <v-card-title>Recent Timeclock Entries</v-card-title>
      <v-data-table :items="timeEntries" :headers="headers" hide-default-footer>
        <template v-slot:item.status="{ item }">
          <v-icon v-if="item.icon" :color="item.iconColor" left>{{ item.icon }}</v-icon>
          {{ item.status }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import { computed, onMounted, ref } from '@vue/composition-api'
import { stringFormatDate, stringFormatDateToday } from '@/helpers/formatters'

export default {
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const aidy = computed({
      get: () => root.$store.state['work-study'].aidy,
      set: (aidy) => root.$store.commit('work-study/setAidy', aidy)
    })
    const positions = ref([])
    const checklistId = ref('')
    const checklist = ref([])
    const checklistItems = [
      {
        code: 'WB',
        title: 'Onboarding Forms',
        text: 'Please check to ensure that all forms have been submitted. There are 7 total forms that you need to submit through WorkBright before you are eligible to work. Once all forms are submitted (including the secondary I9 review), you can mark this as completed.',
        links: [
          { text: 'Complete Onboarding Forms', href: 'https://covenant.workbright.com/users/saml/sign_in' }
        ],
        canMarkComplete: true
      },
      {
        code: 'W4',
        title: 'Withholding Review',
        text: 'If you claimed exempt from taxes on your W-4 or G-4, you need to update annually to show that you are still exempt. Please review your tax withholding information to make sure it is correct and nothing needs to be updated. You can see your withholding information on the Employee Dashboard, by expanding the Taxes section. If you do need to update anything, email Alma Patton (alma.patton@covenant.edu) with a request to update your W4 form. She will then contact you with more information. If you do not need to update anything then you can mark this item as completed.',
        links: [
          { text: 'Review Tax Withholdings', href: 'https://bannerweb.covenant.edu:8443/EmployeeSelfService/ssb/hrDashboard' }
        ],
        canMarkComplete: true
      },
      {
        code: 'DD',
        title: 'Direct Deposit Review',
        text: 'Please review your direct deposit details to make sure the account information is correct to make sure you receive your pay. If you do need to update anything, email Alma Patton (alma.patton@covenant.edu) with a request to update your direct deposit. She will then contact you with more information. If you do not need to update anything then you can mark this item as completed.',
        links: [
          { text: 'Review Direct Deposit', href: 'https://bannerweb.covenant.edu:8443/BannerGeneralSsb/ssb/directDeposit' }
        ],
        canMarkComplete: true
      },
      {
        code: 'SC',
        title: 'ScotsWork Contract',
        text: 'Please review and complete the ScotsWork contract so you understand what is expected of you for your ScotsWork job. This includes information security, hours reporting, and general behavior.',
        links: [{ text: 'Complete ScotsWork Contract', to: '/scotswork/contract' }],
        canMarkComplete: false
      },
      {
        code: 'SV',
        title: 'Workplace Safety Video',
        text: 'Please watch this workplace safety video. Even if you have watched it before, it is important to watch it again to remember what to do in case of an emergency. We hope you never need the content of this video, but want to make sure you are prepared just in case.',
        video: 'ZddXbdSQ0bk',
        playAgain: false,
        canMarkComplete: false
      }
    ]
    const checklistComplete = computed(() => checklist.value.filter(({ completed }) => completed == null).length === 0)
    const timeEntries = ref([])
    const headers = ref([
      { text: 'Clock-In', value: 'inDate' },
      { text: 'Timeclock (in)', value: 'inTimeclock' },
      { text: 'Clock-Out', value: 'outDate' },
      { text: 'Timeclock (out)', value: 'outTimeclock' },
      { text: 'Status', value: 'status' }
    ])

    onMounted(async () => {
      if (user.value.roles.includes('Employee')) {
        root.$router.replace('/scotswork/admin')
      } else {
        if (aidy.value === '') aidy.value = '2425'
        await loadChecklist()
        await loadTimeEntries()
      }
    })

    async function loadChecklist () {
      if (positions.value.length === 0) await loadDetails()
      checklist.value = []
      const { data } = await root.$feathers.service('work-study/checklist').find({ query: { aidy: aidy.value, pidm: user.value.pidm } })
      if (data.length > 0 && 'checklist' in data[0] && Array.isArray(data[0].checklist)) {
        checklistId.value = data[0]._id
        for (const { code, completed } of data[0].checklist) {
          const rec = checklistItems.find(({ code: c }) => c === code)
          if (rec) {
            checklist.value.push({ ...rec, completed })
          }
        }
        for (let i = 0; i < positions.value.length; i++) {
          const rec = positions.value[i]
          const temp = data[0].positions.find(({ code }) => code === rec.code)
          if (temp) {
            positions.value.splice(i, 1, { ...rec, hours: temp.hours })
          }
        }
        await root.$feathers.service('work-study/checklist').patch(checklistId.value, { positions: positions.value })
      }
    }

    async function loadDetails () {
      const { data } = await root.$feathers.service('work-study/students').find({ query: { aidy: aidy.value, pidm: user.value.pidm } })
      if (data.length > 0) {
        const codes = data.map(({ code }) => code)
        const { data: placeData } = await root.$feathers.service('work-study/placements').find({ query: { code: { $in: codes }, $select: ['code', 'name', 'supervisor', 'email'] } })
        positions.value = data.map(({ code, start, end }) => {
          const temp = placeData.find(({ code: c }) => code === c)
          if (temp) {
            return { placement: code, start, end, ...temp }
          } else {
            return { placement: code, start, end }
          }
        })
      }
    }

    async function loadTimeEntries () {
      const { data: timeclocks } = await root.$feathers.service('timeclock/setup').find({ query: { $limit: 50, $sort: { location: 1 }, $select: ['_id', 'location'] } })
      const start = positions.value.reduce((prev, { start }) => {
        const dt = new Date(start)
        return prev == null || dt < prev ? dt : prev
      }, null)
      const { data } = await root.$feathers.service('timeclock/punch').find({ query: { pidm: user.value.pidm, 'in.date': { $gte: start }, $sort: { 'in.date': -1 }, $limit: 10 } })
      timeEntries.value = data.map((row) => {
        if ('in' in row && 'timeclock' in row.in) {
          const rec = timeclocks.find(({ _id }) => row.in.timeclock === _id)
          if (rec) row.inTimeclock = rec.location
          row.inDate = stringFormatDateToday(row.in.date)
        }
        if ('out' in row && 'timeclock' in row.out) {
          const rec = timeclocks.find(({ _id }) => row.out.timeclock === _id)
          if (rec) row.outTimeclock = rec.location
          row.outDate = stringFormatDateToday(row.out.date)
        } else if (row.status === 'P') {
          row.outDate = '**Still Clocked In**'
        } else if (row.status === 'R') {
          row.outDate = '**No Clock Out**'
        }

        if (row.status === 'C') {
          row.status = 'Updated in Banner'
          row.icon = 'fas fa-check-circle'
          row.iconColor = 'success'
        } else if (row.status === 'R') {
          const index = row.reason.indexOf(';')
          row.status = 'Rejected: ' + (index >= 0 ? row.reason.substring(0, index) : row.reason)
          row.icon = 'fas fa-times-circle'
          row.iconColor = 'error'
        } else if (row.status === 'P' && 'out' in row) {
          row.status = 'Pending Update to Banner'
          row.icon = 'fas fa-question-circle'
          row.iconColor = 'info'
        }
        return row
      })
    }

    async function markCompleted (code) {
      const { checklist } = await root.$feathers.service('work-study/checklist').get(checklistId.value)
      if (checklist.length === 0 || checklist.filter(({ code: c }) => c === code).length === 0) {
        await root.$feathers.service('work-study/checklist').patch(checklistId.value, { $push: { checklist: { code, completed: new Date() } } })
      } else {
        await root.$feathers.service('work-study/checklist').patch(checklistId.value, { 'checklist.$[elem].completed': new Date() }, { query: { arrayFilters: [{ 'elem.code': code }] } })
      }
      await loadChecklist()
    }

    return {
      user,
      aidy,
      positions,
      checklist,
      checklistComplete,
      timeEntries,
      headers,
      markCompleted,
      stringFormatDate,
      stringFormatDateToday
    }
  }
}
</script>
